import { ConfigActionTypes } from './actionType';
import * as API from '../../../api/configAPI';
import errorHandler from '../../../utility/errorHandler/errorHandler';
import storage from '../../../utility/storage';

export const config = () => dispatch => dispatch({
    type: ConfigActionTypes.PULL_CONFIG,
    payload: API.fetchConfig()
        .then(response => {
            if (response) {
                if (response.data) {
                    if (response.data.success == 'true') {
                        console.log("response from config", response.data)
                        return response.data;
                    }
                }
            }
        })
        .catch(error => {
            console.log(error, "error from config");
            errorHandler(error);
            return error;
        })
});