import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import { routes } from '../../utility/constants/constants';
import { GOOGLE_LOGIN_CLIENT_ID, FB_LOGIN_APP_ID } from '../../config';

import { auth, provider } from '../../firebase/firebase';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

export const FaceBook = (props) => {

    return (
        <FacebookLogin
            appId={FB_LOGIN_APP_ID()}
            autoLoad={false}
            callback={props.socialMediaResponse}
            fields="name,email,picture"
            render={renderProps => {
                const currentPath = props.history.location.pathname
                const onClickFBLogin = () => {
                    if (props.values.register_checkbox || currentPath === routes.LOGIN) {
                        props.toggleSocialMediaButtons({ google: false, fb: true })
                        return renderProps.onClick()
                    }
                    else {
                        props.setFieldError('register_checkbox', 'You must agree to our Terms & Conditions!')
                        props.setFieldTouched('register_checkbox', true)
                    }
                }
                return (
                    <a href="javascript:void(0)" onClick={onClickFBLogin} className="icn_fa icn_facebook"><img src="images/icons/icn_fb.png" alt="FACEBOOK" />{currentPath === routes.LOGIN ? "Sign In with Facebook" : "Sign Up with Facebook"}</a>
                )
            }}
        />
    )
}

export const Google = (props) => {
    const currentPath = props.history.location.pathname;
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    auth.languageCode = 'it';
    // provider.setCustomParameters({
    //     'login_hint': 'user@example.com'
    //   });

    const handleGoogleLogin = () => {
        debugger
        if(props.values.register_checkbox  || currentPath === routes.LOGIN || currentPath == routes.ROOT) {
            props.toggleSocialMediaButtons({ google: true, fb: false })
            signInWithPopup(auth, provider)
            .then((response) => {
                debugger  
                const credential = GoogleAuthProvider.credentialFromResult(response); // This gives you a Google Access Token. You can use it to access the Google API.
                const user = response.user; // The signed-in user info.
                // IdP data available using getAdditionalUserInfo(result)
                props.socialMediaResponse(credential, user);
            }).catch((error) => {
                debugger
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                // const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });
        } else {
            props.setFieldError('register_checkbox', 'You must agree to our Terms & Conditions!');
            props.setFieldTouched('register_checkbox', true);
            return false;
        }
    };

    return (
        <div>
            <a href="javascript:void(0)" onClick={handleGoogleLogin} className="icn_fa icn_google"><img src="images/icons/icn_google.png" alt="GOOGLE" />{currentPath === routes.LOGIN ? "Sign In with Google" : "Sign Up with Google"}</a>
        </div>
    )
}