import React from 'react';
import { useDispatch } from "react-redux";
import * as actions from '../../redux/actions/index';
import { Form, Formik } from 'formik';

const Tbdpopup = (props) => {

    const dispatch = useDispatch();

    const closePopUp = () => {
        dispatch(actions.closePopUp());
    }

    return (
        <div data-backdrop="static" data-keyboard="false" className="modal fade bd-example-modal-sm avh_modal" id="alert_tbd" tabindex="-1" role="dialog" aria-labelledby="alert_seven" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-sm avh_modal_sm400" role="document">

               

                 <Formik>
                    {(formicProps) => (
                        <Form className="modal-content">
                            <div className="modal-body">
                                <article className="alert_box text-center">
                                    <h3> Please reach out to the customer to establish an arrival time.
                                    </h3>
                                </article>
                                
                                <div className="avh_seprator avh_sep_xl"></div>
                            </div>
                            <div className="modal-footer">
                               
                                <button type="button" onClick={closePopUp} className="theme_btn theme_outline_primary modal_btns_w" data-dismiss="modal"> OK! </button>
                            </div>
                        </Form>
                    )}
                </Formik> 
            </div>
        </div>
    )

}

export default Tbdpopup;