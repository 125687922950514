import Swal from "sweetalert2";
import { themeBlueColor, themeYellowColor } from "../constants/constants";
export const sweetSuccessAlert = (title, text, buttonText) => {
  return Swal.fire({
    title: title,
    text: text,
    icon: "success",
    confirmButtonText: buttonText,
  });
};

export const showConfirmAlert = (title, subtitle, callBack, cancelCallBack) => {
  return Swal.fire({
    title: title,
    text: subtitle,
    icon: "",
    showCancelButton: true,
    confirmButtonColor: themeYellowColor,
    cancelButtonColor: "#d33",
    confirmButtonText: "Confirm",
  }).then((result) => {
    if (result.value) {
      callBack();
    } else if (result.dismiss) {
      // cancelCallBack();
    }
  });
};

export const sweetWarningAlert = (subtitle, title, buttonText) => {
  return Swal.fire({
    width: 450,
    padding: '1em',
    color: '#ffcc06',
    background: '#20201f',
    position: 'top-end',
    icon: 'warning',
    title: title,
    text: subtitle,
    showConfirmButton: false,
    timer: 1000
  });
};