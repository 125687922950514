export const AuthActionTypes = {

    RE_EDIT_PHONE_NUMBER: 'RE_EDIT_PHONE_NUMBER',

    DISPATCH_UPDATE_ACCESS_TOKEN: 'DISPATCH_UPDATE_ACCESS_TOKEN',

    UPDATE_ACCESS_TOKEN: "UPDATE_ACCESS_TOKEN",
    UPDATE_ACCESS_TOKEN_PENDING: "UPDATE_ACCESS_TOKEN_PENDING",
    UPDATE_ACCESS_TOKEN_FULFILLED: "UPDATE_ACCESS_TOKEN_FULFILLED",

    GET_FAQ: "GET_FAQ",
    GET_FAQ_PENDING: "GET_FAQ_PENDING",
    GET_FAQ_FULFILLED: "GET_FAQ_FULFILLED",

    LOGIN: "LOGIN",
    LOGIN_PENDING: "LOGIN_PENDING",
    LOGIN_FULFILLED: "LOGIN_FULFILLED",

    SIGNUP: "SIGNUP",
    SIGNUP_PENDING: "SIGNUP_PENDING",
    SIGNUP_FULFILLED: "SIGNUP_FULFILLED",

    SEND_OTP: "SEND_OTP",
    SEND_OTP_PENDING: "SEND_OTP_PENDING",
    SEND_OTP_FULFILLED: "SEND_OTP_FULFILLED",

    SUBMIT_OTP: "SUBMIT_OTP",
    SUBMIT_OTP_PENDING: "SUBMIT_OTP_PENDING",
    SUBMIT_OTP_FULFILLED: "SUBMIT_OTP_FULFILLED",

    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    FORGOT_PASSWORD_PENDING: "FORGOT_PASSWORD_PENDING",
    FORGOT_PASSWORD_FULFILLED: "FORGOT_PASSWORD_FULFILLED",

    RESET_PASSWORD: "RESET_PASSWORD",
    RESET_PASSWORD_PENDING: "RESET_PASSWORD_PENDING",
    RESET_PASSWORD_FULFILLED: "RESET_PASSWORD_FULFILLED",

    CHANGE_PASSWORD: "CHANGE_PASSWORD",
    CHANGE_PASSWORD_PENDING: "CHANGE_PASSWORD_PENDING",
    CHANGE_PASSWORD_FULFILLED: "CHANGE_PASSWORD_FULFILLED",

    AUTHORIZE: "AUTHORIZE",
    LOGOUT: "LOGOUT",

    UPDATE_USER: "UPDATE_USER",
    UPDATE_USER_PENDING: "UPDATE_USER_PENDING",
    UPDATE_USER_FULFILLED: "UPDATE_USER_FULFILLED",

    GET_USER_PROFILE: 'GET_USER_PROFILE',
    GET_USER_PROFILE_PENDING: 'GET_USER_PROFILE_PENDING',
    GET_USER_PROFILE_FULFILLED: 'GET_USER_PROFILE_FULFILLED',

    EDIT_PROFILE: 'EDIT_PROFILE',
    EDIT_PROFILE_PENDING: 'EDIT_PROFILE_PENDING',
    EDIT_PROFILE_FULFILLED: 'EDIT_PROFILE_FULFILLED',

    UPLOAD_PROFILE_PHOTO: 'UPLOAD_PROFILE_PHOTO',
    UPLOAD_PROFILE_PHOTO_PENDING: 'UPLOAD_PROFILE_PHOTO_PENDING',
    UPLOAD_PROFILE_PHOTO_FULFILLED: 'UPLOAD_PROFILE_PHOTO_FULFILLED',

    UPLOAD_PROFILE_PHOTO_TO_S3BUCKET: 'UPLOAD_PROFILE_PHOTO_TO_S3BUCKET',
    UPLOAD_PROFILE_PHOTO_TO_S3BUCKET_PENDING: 'UPLOAD_PROFILE_PHOTO_TO_S3BUCKET_PENDING',
    UPLOAD_PROFILE_PHOTO_TO_S3BUCKET_FULFILLED: 'UPLOAD_PROFILE_PHOTO_TO_S3BUCKET_FULFILLED',

    HERO_CREATE_TEST_QUESTION: 'HERO_CREATE_TEST_QUESTION',
    HERO_CREATE_TEST_QUESTION_PENDING: 'HERO_CREATE_TEST_QUESTION_PENDING',
    HERO_CREATE_TEST_QUESTION_FULFILLED: 'HERO_CREATE_TEST_QUESTION_FULFILLED',

    HERO_SUBMIT_TEST_QUESTION: 'HERO_SUBMIT_TEST_QUESTION',
    HERO_SUBMIT_TEST_QUESTION_PENDING: 'HERO_SUBMIT_TEST_QUESTION_PENDING',
    HERO_SUBMIT_TEST_QUESTION_FULFILLED: 'HERO_SUBMIT_TEST_QUESTION_FULFILLED',

    HERO_GET_TEST_RESULTS: 'HERO_GET_TEST_RESULTS',
    HERO_GET_TEST_RESULTS_PENDING: 'HERO_GET_TEST_RESULTS_PENDING',
    HERO_GET_TEST_RESULTS_FULFILLED: 'HERO_GET_TEST_RESULTS_FULFILLED',

    UPDATE_IS_SIGNINGUP_FROM_BOOKING: "UPDATE_IS_SIGNINGUP_FROM_BOOKING",

    SET_EMAIL_PATH: "SET_EMAIL_PATH"
}


export const ConfigActionTypes = {
    PULL_CONFIG: "PULL_CONFIG",
    PULL_CONFIG_PENDING: "PULL_CONFIG_PENDING",
    PULL_CONFIG_FULFILLED: "PULL_CONFIG_FULFILLED",

    TOGGLE_FOOTER: "TOGGLE_FOOTER",
}

export const HistoryActionTypes = {
    ADD_HISTORY: "ADD_HISTORY"
}

export const ActionTypes = {

    OPEN_CANCEL_POPUP: 'OPEN_CANCEL_POPUP',
    OPEN_TBD_POPUP: 'OPEN_TBD_POPUP',
    OPEN_COMPLETE_POPUP: 'OPEN_COMPLETE_POPUP',
    RESCHEDULE_JOB_CLICKED: 'RESCHEDULE_JOB_CLICKED',
    MISCELLANEOUS_COST_CLICKED: 'MISCELLANEOUS_COST_CLICKED',
    VIEW_MISCELLANEOUS_COST_CLICKED: 'VIEW_MISCELLANEOUS_COST_CLICKED',
    OPEN_CONTEST_JOB: 'OPEN_CONTEST_JOB',
    RATE_AND_REVIEW_CLCIKED: 'RATE_AND_REVIEW_CLCIKED',
    CLOSE_POPUP: 'CLOSE_POPUP',

    UPDATE_BOOKING_STATE: 'UPDATE_BOOKING_STATE',

    GET_TIME_ZONE: 'GET_TIME_ZONE',
    GET_TIME_ZONE_PENDING: 'GET_TIME_ZONE_PENDING',
    GET_TIME_ZONE_FULFILLED: 'GET_TIME_ZONE_FULFILLED',

    ADD_CARD: 'ADD_CARD',
    ADD_CARD_PENDING: 'ADD_CARD_PENDING',
    ADD_CARD_FULFILLED: 'ADD_CARD_FULFILLED',

    GET_CARD_DETAILS: 'GET_CARD_DETAILS',
    GET_CARD_DETAILS_PENDING: 'GET_CARD_DETAILS_PENDING',
    GET_CARD_DETAILS_FULFILLED: 'GET_CARD_DETAILS_FULFILLED',

    BOOKING_CREATED: 'BOOKING_CREATED',

    CREATE_BOOKING: 'CREATE_BOOKING',
    CREATE_BOOKING_PENDING: 'CREATE_BOOKING_PENDING',
    CREATE_BOOKING_FULFILLED: 'CREATE_BOOKING_FULFILLED',

    GET_PAYOUTS: 'GET_PAYOUTS',
    GET_PAYOUTS_PENDING: 'GET_PAYOUTS_PENDING',
    GET_PAYOUTS_FULFILLED: 'GET_PAYOUTS_FULFILLED',

    GET_CLIENT_ORDERS: 'GET_CLIENT_ORDERS',
    GET_CLIENT_ORDERS_PENDING: 'GET_CLIENT_ORDERS_PENDING',
    GET_CLIENT_ORDERS_FULFILLED: 'GET_CLIENT_ORDERS_FULFILLED',

    GET_USER_FAVORITE_HEROS_LIST: 'GET_USER_FAVORITE_HEROS_LIST',
    GET_USER_FAVORITE_HEROS_LIST_PENDING: 'GET_USER_FAVORITE_HEROS_LIST_PENDING',
    GET_USER_FAVORITE_HEROS_LIST_FULFILLED: 'GET_USER_FAVORITE_HEROS_LIST_FULFILLED',

    DELETE_USER_FAVOURITE_HERO: 'DELETE_USER_FAVOURITE_HERO',
    DELETE_USER_FAVOURITE_HERO_PENDING: 'DELETE_USER_FAVOURITE_HERO_PENDING',
    DELETE_USER_FAVOURITE_HERO_FULFILLED: 'DELETE_USER_FAVOURITE_HERO_FULFILLED',

    GET_CLINET_BOOKING_LIST: 'GET_CLINET_BOOKING_LIST',
    GET_CLINET_BOOKING_LIST_PENDING: 'GET_CLINET_BOOKING_LIST_PENDING',
    GET_CLINET_BOOKING_LIST_FULFILLED: 'GET_CLINET_BOOKING_LIST_FULFILLED',

    GET_HERO_JOBS_LIST: 'GET_HERO_JOBS_LIST',
    GET_HERO_JOBS_LIST_PENDING: 'GET_HERO_JOBS_LIST_PENDING',
    GET_HERO_JOBS_LIST_FULFILLED: 'GET_HERO_JOBS_LIST_FULFILLED',

    CANCEL_BOOKING: 'CANCEL_BOOKING',
    CANCEL_BOOKING_PENDING: 'CANCEL_BOOKING_PENDING',
    CANCEL_BOOKING_FULFILLED: 'CANCEL_BOOKING_FULFILLED',
    
    ACCEPT_JOB: 'ACCEPT_JOB',
    ACCEPT_JOB_PENDING: 'ACCEPT_JOB_PENDING',
    ACCEPT_JOB_FULFILLED: 'ACCEPT_JOB_FULFILLED',

    RESCHEDULE_JOB: 'RESCHEDULE_JOB',
    RESCHEDULE_JOB_PENDING: 'RESCHEDULE_JOB_PENDING',
    RESCHEDULE_JOB_FULFILLED: 'RESCHEDULE_JOB_FULFILLED',

    ADD_MISCELLANEOUS_COST: 'ADD_MISCELLANEOUS_COST',
    ADD_MISCELLANEOUS_COST_PENDING: 'ADD_MISCELLANEOUS_COST_PENDING',
    ADD_MISCELLANEOUS_COST_FULFILLED: 'ADD_MISCELLANEOUS_COST_FULFILLED',

    GET_MISCELLANEOUS_PRESIGNED_URL: 'GET_MISCELLANEOUS_PRESIGNED_URL',
    GET_MISCELLANEOUS_PRESIGNED_URL_PENIDNG: 'GET_MISCELLANEOUS_PRESIGNED_URL_PENDING',
    GET_MISCELLANEOUS_PRESIGNED_URL_FULFILLED: 'GET_MISCELLANEOUS_PRESIGNED_URL_FULFILLED',

    ADD_RATE_AND_REVIEW: 'ADD_RATE_AND_REVIEW',
    ADD_RATE_AND_REVIEW_PENDING: 'ADD_RATE_AND_REVIEW_PENDING',
    ADD_RATE_AND_REVIEW_FULFILLED: 'ADD_RATE_AND_REVIEW_FULFILLED',

    REPOST_JOB: 'REPOST_JOB',
    REPOST_JOB_PENDING: 'REPOST_JOB_PENDING',
    REPOST_JOB_FULFILLED: 'REPOST_JOB_FULFILLED',

    ACCEPT_RESCHEDULED_JOB: 'ACCEPT_RESCHEDULED_JOB',
    ACCEPT_RESCHEDULED_JOB_PENDING: 'ACCEPT_RESCHEDULED_JOB_PENDING',
    ACCEPT_RESCHEDULED_JOB_FULFILLED: 'ACCEPT_RESCHEDULED_JOB_FULFILLED',

    UPDATE_BOOKING: 'UPDATE_BOOKING',
    UPDATE_BOOKING_PENDING: 'UPDATE_BOOKING_PENDING',
    UPDATE_BOOKING_FULFILLED: 'UPDATE_BOOKING_FULFILLED',

    CONTEST_JOB: 'CONTEST_JOB',
    CONTEST_JOB_PENDING: 'CONTEST_JOB_PENDING',
    CONTEST_JOB_FULFILLED: 'CONTEST_JOB_FULFILLED',

    BLOCK_ROUTE: 'BLOCK_ROUTE',

    BOOKING_METRICS: 'BOOKING_METRICS',
    BOOKING_METRICS_FULLFILLED: 'BOOKING_METRICS_FULFILLED',

    FETCH_STRIPE_CONNECT_URL: 'FETCH_STRIPE_CONNECT_URL',
    FETCH_STRIPE_CONNECT_URL_PENDING: 'FETCH_STRIPE_CONNECT_URL_PENDING',
    FETCH_STRIPE_CONNECT_URL_FULFILLED: 'FETCH_STRIPE_CONNECT_URL_FULFILLED',
}

