import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA1G-FnlgAHRMu_bkEl3cbR7BEHyJwNhTk",
  authDomain: "avhero.firebaseapp.com",
  projectId: "avhero",
  storageBucket: "avhero.appspot.com",
  messagingSenderId: "581842886969",
  appId: "1:581842886969:web:6174a6707885de9e1648e8"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Create an instance of the Google provider object
export const provider = new GoogleAuthProvider();